import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";

Chart.register(annotationPlugin);
Chart.register(CategoryScale);
// Chart.register(ChartDataLabels);
Chart.defaults.font.family = "Versa Versa";

const CURRENT_TARGET = 50000;

function FundraisingBar() {
  const [chartData, setChartData] = useState({
    labels: [""],
    datasets: [
      {
        label: "Donations",
        backgroundColor: "#00FF00",
        data: [0],
      },
      {
        label: "Together Fundraise Matching",
        backgroundColor: "#b088ff",
        data: [0],
      },
      {
        label: "Additional Fundraising",
        backgroundColor: "#00de09",
        data: [0],
      },
      {
        label: "Gift Aid",
        backgroundColor: "#253c75",
        data: [0],
      }
    ],
  });
  const [chartOptions, setChartOptions] = useState({})
  const [total, setTotal] = useState(0);

  const update = () => {
    fetch("https://api.justgiving.com/d4fe6f1f/v1/teamsv3/mcr-24-hour-run-2024", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
        .then(async (http) => {
          let content = http.json();
          if (http.ok) {
            return content;
          } else {
            return content.then(content => { throw new Error(content); });
          }
        })
        .then((response) => {
          console.log("Updating chart");

          const teamTotal = response.donationSummary.totalAmount;
          const fundraiseMatchingTotal = teamTotal < 20000 ? teamTotal : 20000;
          const additionalFundraising = 1240;
          const giftAidTotal = response.donationSummary.totalGiftAid;

          const completeTotal = teamTotal + fundraiseMatchingTotal + additionalFundraising + giftAidTotal;
          setTotal(completeTotal);

          setChartData({
            labels: [""],
            datasets: [
              {
                label: "Donations",
                backgroundColor: "#00ff00",
                data: [teamTotal],
              },
              {
                label: "Together Fundraise Matching",
                backgroundColor: "#b088ff",
                data: [fundraiseMatchingTotal],
              },
              {
                label: "Additional Fundraising",
                backgroundColor: "red",
                data: [additionalFundraising],
              },
              {
                label: "Gift Aid",
                backgroundColor: "#7DF9FF",
                data: [giftAidTotal],
              }
            ]
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
  };

  // Update chart options and annotations
  useEffect(() => {

    setChartOptions({
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        datalabels: {
          font: {
            size: 50,
          },
          formatter: (val: number) => "£" + val,
          color: "#000000",
          display: false,
        },
        title: {
          display: false,
          text: "Fundraising",
          font: {
            size: 30,
          },
        },
        legend: {
          display: true,
          textAlign: "left",
          align: "end",
          position: "top"
        },
        annotation: {
          annotations: {
            targetLine: {
              type: 'line',
              xMin: CURRENT_TARGET,
              xMax: CURRENT_TARGET,
              borderColor: 'rgb(158, 11, 133)',
              borderWidth: 5
            },
            targetLabel: {
              type: 'label',
              xValue: CURRENT_TARGET,
              content: [`£${new Intl.NumberFormat('en-gb', { maximumFractionDigits: 2 }).format(CURRENT_TARGET)} Target`],
              font: {
                size: 16
              },
              backgroundColor: 'rgb(158, 11, 133)',
              borderRadius: 10,
              color: '#ffffff',
              position: 'end',
              padding: 15,
              display: false,
              // display: total < CURRENT_TARGET - 1920,
            },
            totalLine: {
              type: 'line',
              xMin: total,
              xMax: total,
              borderColor: 'rgb(158, 11, 133)',
              borderWidth: 5,
              zIndex: 10,
            },
            totalLabel: {
              type: 'label',
              xValue: total - 200,
              content: [`Total: £${new Intl.NumberFormat('en-gb', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(total)}`],
              font: {
                size: 16,
              },
              backgroundColor: 'rgb(158, 11, 133)',
              borderRadius: 10,
              color: '#ffffff',
              position: 'end',
              yAdjust: 15,
            },
            buffer: {
              type: 'point',
              xValue: 50000,
              radius: 0,
              display: false,
            }
          }
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: function (value: string) {
              return '\u00A3' + value;
            }
          }
        },
        y: {
          stacked: true,
        },
      },
      categoryPercentage: 1.0,
      barPercentage: 1,
    });
  }, [total]); // Re-run this when `total` changes

  // Initial fetch and periodic update
  useEffect(() => {
    update();
    // const interval = setInterval(() => {
    //   update();
    // }, 60000); // Update every 60 seconds
    // return () => clearInterval(interval);
  }, []); // Only run once on mount

  return (
      <Bar className="m-4" data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
  );
}

export default FundraisingBar;
